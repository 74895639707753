import '../css/main.scss';
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
// import "../js/magnificpopup";
import '../js/bootstrap';
import '../js/glide';
import "../js/fontawesome";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();

$(function() {

  // password validation
  if (document.getElementById("password")) {
    var myInput = document.getElementById("password");
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");

    // When the user starts to type something inside the password field
    myInput.onkeyup = function() {
      // Validate lowercase letters
      var lowerCaseLetters = /[a-z]/g;
      if(myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
      }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if(myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if(myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }

      // Validate length
      if(myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }

    }
  }

  if (document.getElementById("passwordConfirm")) {
    
    if(!$("#submit").hasClass("do-not-disable")) {
      $("#submit").addClass("disabled");
    }

    $("#passwordConfirm").keyup(function() {
      var password = $("#password").val();
      if (password == $(this).val()) {
        $("#submit").removeClass("disabled");
      } else {
        $("#submit").addClass("disabled");
      }
      console.log(password == $(this).val()
          ? "Passwords match."
          : "Passwords do not match!"
      );
    });
    
  }

  

  if (document.getElementById("newPassword")) {
    var myInputNew = document.getElementById("newPassword");
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");

    myInputNew.onkeyup = function() {
      // Validate lowercase letters
      var lowerCaseLetters = /[a-z]/g;
      if(myInputNew.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
    }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if(myInputNew.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if(myInputNew.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }

      // Validate length
      if(myInputNew.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }
    }

    $(".toggle-password").click(function() {
      $(this).children('.fa-eye').toggleClass('d-block d-none');
      $(this).children('.fa-eye-slash').toggleClass('d-none d-block');

      var input = $(this).prev('input');
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  if (document.getElementsByClassName("neo-channel")) {
    $(".neo-channel .glide__slide a").hover(
      function() {
        $(this).children("video")[0].play();
      }, function() {
        $(this).children("video")[0].pause();
        // $(this).children("video")[0].currentTime = 0;
    });
  }  

  if (document.getElementById("reset")) {
    $('#reset').on('click', function() {
      $(".selectpicker").selectpicker('deselectAll');
    });
  }

  if (document.getElementsByClassName("toggle-password")) {
    $(".toggle-password").on('click', function() {
      $(this).children('.fa-eye').toggleClass('d-block d-none');
      $(this).children('.fa-eye-slash').toggleClass('d-none d-block');

      var input = $(this).prev('input');
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  $('.navbar-toggler').on('click', function(){
    $('#header').toggleClass('active');
  })

});