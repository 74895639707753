import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight, faPlayCircle as falPlayCircle, faTimes as falTimes, faCheck as falCheck } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle, faCheck as farCheck, faRedoAlt as farRedoAlt, faSignIn as farSignIn, faSignOut as farSignOut, faBars as farBars, faHourglassHalf as farHourglassHalf, faEye as farEye, faEyeSlash as farEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faSpinner as fasSpinner, faUser as fasUser, faBars as fasBars, faPencilAlt as fasPencilAlt, faCrown as fasCrown, faBriefcase as fasBriefcase, faGlobeAfrica as fasGlobeAfrica, faEnvelope as fasEnvelope} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falQuoteLeft, falQuoteRight, falPlayCircle, falCheck, falTimes);
// regular
library.add(farSearch, farCircle, farCheck, farRedoAlt, farSignIn, farSignOut, farBars, farHourglassHalf, farEye, farEyeSlash);
// solid
library.add(faChevronRight, faChevronLeft, faChevronDown, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, fasUser, fasBars, fasPencilAlt, fasCrown, fasBriefcase, fasGlobeAfrica, fasEnvelope);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG);

dom.i2svg();
dom.watch();